import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import VariableSubstitutionDialog from '@/components/VariableSubstitutionDialog/VariableSubstitutionDialog.vue'
import CountryService from '@/services/country.service'
import EmojiPickerInput from '@/components/EmojiPickerInput/EmojiPickerInput.vue'
import EventBus from '@/util/EventBus'
import moment from 'moment'
import AutoAnswerType from '../../../../../models/AutoAnswerType'
import ModelMessageInteractive from '../../../../../models/whatsapp/MessageInteractive'
import ModelTextOnly from '../../../../../models/whatsapp/TextOnly'
import MessageInteractive from '@/components/Whatsapp/MessageInteractive/MessageInteractive.vue'
import TextFormatting from '@/components/TextFormatting/TextFormatting.vue'

export default {
  name: 'AutoAnswer',
  props: {
    channel: {
      type: Object,
      required: true,
    },
    autoAnswer: {
      type: Object,
      required: true,
    },
    autoAnswers: {
      type: Array,
      required: true,
    },
    preview: {
      type: Object,
      required: true,
    },
    currentWhatsapp: {
      type: Object,
      required: true,
    },
    fields: {
      type: Object,
      required: true,
    },
    selectedAutoAnswerId: {
      required: true,
    },
  },
  components: {
    SelectCountry,
    VariableSubstitutionDialog,
    EmojiPickerInput,
    MessageInteractive,
    TextFormatting,
  },
  data: function () {
    return {
      autoAnswerType: new AutoAnswerType(),
      autoAnswerPanel: 0,
      countries: [],
      chunkCountChange: false,
      showEmoji: true,
      aiDrawerCtrl: {
        show: false,
      },
      selectedAutoAnswer: this.selectedAutoAnswerId,
    }
  },
  computed: {
    getMinimumDate () {
      return moment(new Date()).format('YYYY-MM-DDTHH:mm')
    },
    countMessages () {
      if (!this.autoAnswer.answer.body) return 0
      return this.autoAnswer.answer.body.length > 0 ? this.autoAnswer.answer.body.length : 0
    },
    fieldsAsArray () {
      const fields = []
      for (const key in this.fields.availableFields) {
        fields.push({ value: key, text: this.fields.availableFields[key] })
      }
      return fields
    },
  },
  methods: {
    changeAutoAnswerType () {
      if (this.autoAnswer.answerType === this.autoAnswerType.text) {
        this.autoAnswer.answer = new ModelTextOnly()
      }

      if (this.autoAnswer.answerType === this.autoAnswerType.interactive) {
        this.autoAnswer.answer = new ModelMessageInteractive()
      }
    },
    openAiAssistant () {
      EventBus.$emit('AutoAnswerAiAssistant:open')
    },
    getAllCountries () {
      CountryService
        .getAllCountries()
        .then(
          (response) => {
            this.countries = response.map((country) => {
              return {
                value: country.iso_code_2,
                text: `${country.name} +(${country.prefix})`,
              }
            })
          },
          () => {},
        )
    },
    addEmojiToMessage (emoji) {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.autoAnswer.answer.body = before + emoji + after

      this.$nextTick().then(() => {
        textarea.selectionStart = textarea.value.length
        textarea.click()
        this.parseWhatsapp()
      })
    },
    addFormatText (type) {
      const message = this.$refs.messageInput.$el.querySelector('textarea')
      const desde = message.selectionStart
      const hasta = message.selectionEnd
      const textSelected = message.value.substring(desde, hasta)
      const text = message.value

      switch (type) {
        case 'strong':
        this.addHtmlTag(text, desde, hasta, textSelected, '*', '*')
        break
      case 'italic':
        this.addHtmlTag(text, desde, hasta, textSelected, '_', '_')
        break
      case 'strike':
        this.addHtmlTag(text, desde, hasta, textSelected, '~', '~')
        break
      case 'monospace':
        this.addHtmlTag(text, desde, hasta, textSelected, '```', '```')
      }
    },
    addHtmlTag (text, desde, hasta, textSelected, tagOpen, tagClose) {
      if (textSelected.length > 0) {
        const replaceText = tagOpen + '' + textSelected + '' + tagClose
        this.autoAnswer.answer.body = text.substring(0, desde) + replaceText + text.substring(hasta)
      }
    },
    addFieldToMessage (item, type, index) {
      if (item !== null) {
        let fieldName = item
        if (type === 'field') {
          fieldName = '{' + item + '}'
        }
        this.insertFieldAtCursor(fieldName, type, index)
      }
    },
    insertFieldAtCursor (myValue, type, index) {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.autoAnswer.answer[index] = before + myValue + after

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + myValue.length
        textarea.click()
      })
    },
    changeAutoAnswer () {
      this.$emit('setAutoAnswer', this.selectedAutoAnswer)
    },
  },
  mounted () {
    setTimeout(() => {
      this.$refs.autoAnswerTextFormatting.setContext(this, this.$refs.messageInput.$el.querySelector('textarea'))
    })
    this.getAllCountries()
  },
}
